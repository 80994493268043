body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* css -> hello-world */
.Unrevealed {
  background-color: darkgray;
}

.Revealed {
  background-color: yellow;
}

.Solved {
  background-color: '#388e3c';
}

.SolvedByGuild {
  background-color: '#0288d1';
}

.Revealed {
  background-color: lavender;
}
